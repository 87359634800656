import React from 'react';

const Carausel = () => {
    return (
<div id="demo" className="carousel slide" data-ride="carousel">
      <div className="carousel-inner">


	<div className="carousel-item active">
          <img src="/images/banner24/MTHQANTARABANGSA.jpg" alt="rtm" />
        </div>


        <div className="carousel-item">
          <img src="/images/banner24/portalrtmklik.jpg" alt="rtm" />
        </div>





        <div className="carousel-item">
          <img src="/images/banner24/GENERIMFL20242025.jpg" alt="rtm" />
        </div>





        <div className="carousel-item">
         <a href="/contents/3/latar-belakang"> <img src="/images/banner24/mediacitylatestncompleted-min.svg" alt="rtm" /> </a>
        </div>


      </div>

      {/* Left and right controls */}
      <a className="carousel-control-prev" href="#demo" data-slide="prev">
        <span className="carousel-control-prev-icon"></span>
      </a>
      <a className="carousel-control-next" href="#demo" data-slide="next">
        <span className="carousel-control-next-icon"></span>
      </a>
    </div>

    );
};

export default Carausel;
